@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";
@use "tools/fonts.scss";
@use "tools/general.scss";

.App {
  overflow-x: hidden;

  section {
    position: relative;
    background-color: colors.$color-yellow-bg;

    &.poisonControl {
      min-height: 105px;
      background: colors.$color-gradient-two;

      @include mediaQueries.break-point(desktop) {
        min-height: 175px;
      }
    }

    &.home {
      padding-bottom: functions.em(230);
      max-height: 960px;

      @media (min-width: 375px) {
        padding-bottom: functions.em(220);
      }

      @media (min-width: 400px) {
        padding-bottom: functions.em(250);
      }

      @include mediaQueries.break-point(landscape-phone) {
        padding-bottom: functions.em(375);
      }

      @include mediaQueries.break-point(desktop) {
        padding-bottom: functions.em(450);
      }

      @include mediaQueries.break-point(desktop-large) {
        max-height: 850px;
      }

      .homeContainer {
        position: relative;
        top: -60px;
        min-height: 100%;
      }

      .headlineAnimation {
        position: absolute;

        img,
        video {
          width: 403px;
          position: relative;
          left: -9%;

          @media (min-width: 375px) {
            left: -5%;
            width: 455px;
          }

          @media (min-width: 400px) {
            left: 0%;
            width: 465px;
          }

          @include mediaQueries.break-point(tablet) {
            width: 750px;
            top: -50px;
            left: -30px;
          }

          @include mediaQueries.break-point(desktop) {
            width: 900px;
            position: relative;
            top: functions.em(-85);
          }
        }
      }

      .headlineContent {
        position: relative;
        top: functions.em(230);

        @include mediaQueries.break-point(tablet) {
          top: functions.em(300);
        }

        @include mediaQueries.break-point(desktop) {
          top: functions.em(220);
          left: 35%;
          max-width: 60%;
          padding-top: 27px;
        }

        @include mediaQueries.break-point(desktop-xl) {
          top: functions.em(250);
          left: 300px;
          max-width: 60%;
        }

        @media (min-width: 1440px) {
          margin-bottom: 30px;
        }
      }

      .videoContent {
        position: relative;
        top: 150px;

        @include mediaQueries.break-point(tablet) {
          top: 250px;
        }
      }

      .videoContainer {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        height: 56.25%;
        z-index: 10;
        margin: functions.em(20) 0;

        @media (min-width: 768px) {
          margin: 0 auto;
        }

        @media (min-width: 1200px) {
          max-width: 960px;
          // margin-left: 30%;
          display: flex;
          align-items: center;
        }

        video {
          max-width: 100%;
        }
      }
    }

    &.poisonStats {
      min-height: 450px;
    }

    &.whyDogsEat {
      min-height: 390px;
      background: colors.$color-gradient-four;

      .dogsEatContainer {
        height: 0;
        width: 100%;

        @media (min-width: 375px) {
          height: unset;
        }
      }

      .tongueAnimation {
        left: 0;
        width: 100%;
        position: relative;
        min-height: 300px;
        top: functions.em(-240);

        @media (min-width: 375px) {
          left: 50%;
          transform: translate(-50%);
          z-index: 1;
        }

        @include mediaQueries.break-point(landscape-phone) {
          top: functions.em(-240);
          left: 50%;
          transform: unset;
        }

        @include mediaQueries.break-point(tablet) {
          left: 60%;
        }

        @include mediaQueries.break-point(desktop) {
          top: functions.em(-340);
          left: 52%;
          min-height: 500px;
        }

        @include mediaQueries.break-point(desktop-large) {
          top: functions.em(-320);
        }

        @include mediaQueries.break-point(desktop-xl) {
          top: functions.em(-320);
        }

        img,
        video {
          width: 700px;
          position: absolute;
          z-index: 3;

          @include mediaQueries.break-point(desktop) {
            width: 1300px;
          }

          &.hidden {
            display: none;
            visibility: hidden;
          }
        }
      }

      .whyDogsEatContainer {
        display: flex;
        flex-direction: column;

        @include mediaQueries.break-point(desktop) {
          flex-direction: row;
          align-items: center;
          column-gap: 100px;
        }
      }

      h1 {
        padding-top: functions.em(45);
        color: colors.$color-white;

        @include mediaQueries.break-point(desktop) {
          padding-top: 0;
          min-width: 520px;
        }
      }

      h2 {
        padding-top: functions.em(45);
        color: colors.$color-white;

        @media (min-width: 375px) {
          padding-top: 0 !important;
          margin-top: -200px;
        }

        @include mediaQueries.break-point(desktop) {
          padding-top: 0;
          min-width: 520px;
          margin-top: -400px;
        }
      }

      p {
        color: colors.$color-white;
        z-index: 4;

        @include mediaQueries.break-point(desktop) {
          margin-top: -400px;
        }

        @include mediaQueries.break-point(desktop-xl) {
        }
      }

      @include mediaQueries.break-point(desktop) {
        min-height: initial;
      }
    }

    &.topPoisons {
      background: colors.$color-gradient-one;

      @include mediaQueries.break-point(desktop) {
        padding-bottom: functions.em(70);
      }

      h2 {
        padding-top: functions.em(10);
        font-family: fonts.$font-family-h2;
      }

      .topDogContainer {
        @include mediaQueries.break-point(desktop) {
          position: relative;
          top: -80px;
        }
      }

      .poisonAnimation {
        position: relative;
        width: 100%;
        left: 0;

        @include mediaQueries.break-point(desktop) {
          margin-top: functions.em(-100);
        }

        img,
        video {
          width: 480px;
          position: relative;
          left: 50%;
          transform: translate(-50%);
          z-index: 5;

          @include mediaQueries.break-point(desktop) {
            width: 780px;
            left: 38%;
            transform: unset;
          }

          @include mediaQueries.break-point(desktop-large) {
            width: 780px;
            left: 50%;
            transform: unset;
          }
        }
      }

      .grapeAnimation {
        img,
        video {
          display: block;
          width: 100%;

          @include mediaQueries.break-point(desktop) {
            display: none;
          }
        }
      }
    }

    &.humanFood {
      background: colors.$color-pink-light;
      scroll-margin-top: 40px;

      .humanFoodContainer {
        display: flex;
        flex-direction: column;

        @include mediaQueries.break-point(desktop) {
          align-items: center;
          column-gap: 60px;
          flex-direction: row;
          padding-top: functions.em(30) !important;
          padding-bottom: functions.em(20);
        }

        h2 {
          padding-top: functions.em(15);

          @include mediaQueries.break-point(desktop) {
            padding-top: 0;
            max-width: 29%;
          }
        }

        p {
          span {
            display: block;
            margin-top: 0.5rem;

            @include mediaQueries.break-point(desktop) {
              display: inline;
              margin-top: 0;
            }
          }

          @include mediaQueries.break-point(desktop) {
            margin-top: 0;
          }
        }
      }
    }

    &.humanFoodList {
      background: colors.$color-gradient-two;
    }

    &.signsOfPoison {
      padding-bottom: functions.em(100);
      background-color: colors.$color-pink-light;
      scroll-margin-top: 120px;

      @include mediaQueries.break-point(desktop) {
        padding-bottom: functions.em(35);
      }

      h2 {
        padding-top: functions.em(5);
      }

      p {
        font-family: fonts.$font-family-p;
        font-size: 18px;
        line-height: 1.2;
      }

      .pillAnimation {
        width: 100%;
        overflow-x: hidden;

        height: 90px;

        @media only screen and (min-width: 335px) {
          height: 110px;
        }

        @media only screen and (min-width: 400px) {
          height: 130px;
        }

        @media only screen and (min-width: 450px) {
          height: 155px;
        }

        @media only screen and (min-width: 520px) {
          height: 175px;
        }

        @media only screen and (min-width: 570px) {
          height: 195px;
        }

        @media only screen and (min-width: 615px) {
          height: 220px;
        }

        @media only screen and (min-width: 690px) {
          height: 245px;
        }

        @media only screen and (min-width: 785px) {
          height: 270px;
        }

        @media only screen and (min-width: 840px) {
          height: 300px;
        }

        @media only screen and (min-width: 925px) {
          height: 325px;
        }

        @include mediaQueries.break-point(desktop) {
          min-height: 100px;
          height: initial;
        }

        img,
        video {
          width: 100%;
          position: absolute;
          top: -55px;
          transform: translateX(5%);
          z-index: 5;

          @media only screen and (min-width: 365px) {
            top: -75px;
          }

          @media only screen and (min-width: 500px) {
            top: -105px;
          }

          @media only screen and (min-width: 630px) {
            top: -115px;
          }

          @media only screen and (min-width: 750px) {
            top: -125px;
          }

          @media only screen and (min-width: 890px) {
            top: -120px;
          }

          @include mediaQueries.break-point(tablet) {
            width: 90%;
            left: 10%;
          }

          @include mediaQueries.break-point(desktop) {
            position: absolute;
            top: functions.em(-190);
            left: 39%;
            width: 720px;
            transform: none;
          }

          @include mediaQueries.break-point(desktop-large) {
            top: functions.em(-160);
            left: 55%;
          }

          @include mediaQueries.break-point(desktop-xl) {
            left: 49%;
          }
        }
      }
    }

    &.safetyCard {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: functions.em(50) 0 functions.em(180);
      margin: 0 auto;

      @include mediaQueries.break-point(desktop) {
        justify-content: space-evenly;
        flex-direction: row;
        padding-bottom: functions.em(40);
      }

      .dogAnimation {
        width: 100%;
        position: absolute;
        top: functions.em(-190);
        left: -17%;

        @include mediaQueries.break-point(landscape-phone) {
          left: 0;
        }

        @include mediaQueries.break-point(tablet) {
          top: functions.em(-200);
        }

        @include mediaQueries.break-point(desktop) {
          top: functions.em(-260);
          left: -11%;
        }

        @include mediaQueries.break-point(desktop-xl) {
          top: functions.em(-230);
          left: -5%;
        }

        img,
        video {
          width: 500px;
          position: relative;
          z-index: 5;

          @include mediaQueries.break-point(desktop) {
            width: 850px;
          }
        }
      }

      .safetyCardContainer {
        @include mediaQueries.break-point(desktop) {
          padding-top: functions.em(65);
        }
      }

      .safetyContent {
        z-index: 10;

        @include mediaQueries.break-point(desktop) {
          width: 50%;
        }
      }

      .catAnimation {
        width: 100%;
        position: relative;
        top: functions.em(-30);

        @include mediaQueries.break-point(landscape-phone) {
          top: functions.em(-10);
        }

        @include mediaQueries.break-point(desktop) {
          top: -20em;
          left: 25%;
        }

        @include mediaQueries.break-point(desktop-large) {
          left: 35%;
        }

        @include mediaQueries.break-point(desktop-xl) {
          left: 40%;
        }

        img,
        video {
          width: 500px;
          position: absolute;
          z-index: 4;
          left: 50%;
          transform: translate(-50%);

          @include mediaQueries.break-point(desktop) {
            width: 900px;
          }

          @include mediaQueries.break-point(desktop-large) {
            left: 0;
            transform: unset;
          }
        }
      }
    }

    &.socialMedia {
      background-image: colors.$color-gradient-two;
    }

    &.partnersSources {
      padding-top: functions.em(50);

      @include mediaQueries.break-point(desktop) {
        padding-top: 0;
      }

      .partnershipContainer {
        @include mediaQueries.break-point(desktop) {
          padding-top: 1px;
        }
      }

      .partnershipText {
        text-align: center;
        font-weight: 500;
      }
    }
  }

  .navAnchor {
    position: absolute;
    // height: 10px;
    // width: 10px;
    // background-color: red;
    top: functions.em(-100);
  }

  .chatButton {
    height: 70px;
    width: 70px;
    margin: 0;
    @extend .bg-image-baselines;
    background-color: transparent;
    background-image: url("../media/images/nav/icon-chatbot-trigger3.png");
    background-size: cover;
    border: none;
    border-radius: 50%;
    position: fixed;
    bottom: 4px;
    right: 3px;
    z-index: 2243001; // kommunicate trigger is 2243000
    cursor: pointer;
    filter: drop-shadow(0 0 0.35rem rgba(0, 0, 0, 0.421));

    @include mediaQueries.break-point(desktop) {
      height: 85px;
      width: 85px;
      bottom: 7px;
      right: 7px;
    }
  }

  .animationPlaceholder {
    height: 150px;
    border: 1px solid green;
    background: repeating-linear-gradient(
      135deg,
      lightblue 0%,
      lightblue 30px,
      white 30px,
      white 60px
    );
    opacity: 0.5;
  }

  .iframePlaceholder {
    width: 100%;
    max-width: 500px;
    height: 56.25%;
    background: repeating-linear-gradient(
      135deg,
      lightgreen 0%,
      lightgreen 30px,
      white 30px,
      white 60px
    );
    opacity: 0.5;

    @include mediaQueries.break-point(desktop) {
      max-width: 610px;
    }
  }

  .subheadText {
    &HumanFood {
      margin-top: 0.5em;

      @include mediaQueries.break-point(desktop) {
        margin-top: 0;
      }
    }

    &Poisoning {
      margin-top: 0.5em;

      @include mediaQueries.break-point(desktop) {
        margin-top: 1.875em;
      }
    }

    &Download {
      margin-top: 0.5em;

      @include mediaQueries.break-point(desktop) {
        margin-top: 1.875em;
      }
    }
  }
}
