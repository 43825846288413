@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";
@use "tools/fonts.scss";

.container {
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 500px;
  top: 150px;
  z-index: 2;
  padding-left: 2%;

  @include mediaQueries.break-point(tablet) {
    top: 250px;
    padding-left: 0%;
  }

  @include mediaQueries.break-point(desktop) {
    margin-bottom: 0;
    top: 300px;
    padding-left: 5%;
    // left: 130px;
  }

  @include mediaQueries.break-point(desktop-large) {
    top: 300px;
    left: -20px;
  }

  @media (min-width: 2000px) {
    top: 270px;
  }
}

.circlesWrapper {
  height: 100%;
  isolation: isolate;
  @include mediaQueries.break-point(desktop-large) {
    max-width: 1000px;
    margin: 0 auto;
  }
}

.circle {
  background-image: colors.$color-gradient-two;
  border-radius: 50%;
  height: 205px;
  width: 205px;
  display: flex;
  align-items: center;
  position: absolute;

  @media (min-width: 280px) {
    height: 120px;
    width: 120px;
    margin-left: -15px;
  }

  @media (min-width: 360px) {
    margin-left: -15px;
    height: 150px;
    width: 150px;
  }

  @media (min-width: 375px) {
    margin-left: 0;
    height: 170px;
    width: 170px;
  }

  @media (min-width: 400px) {
    margin-left: -5px;
    height: 175px;
    width: 175px;
  }

  @include mediaQueries.break-point(desktop) {
    height: 245px;
    width: 245px;
    margin-left: 30px;
  }

  @include mediaQueries.break-point(desktop-large) {
    margin-bottom: 15px;
  }

  &One {
    height: 215px;
    width: 215px;
    border: 10px solid colors.$color-pink-dark;
    left: 50%;
    transform: translateX(100vw);
    transition: all 1s;

    @media (min-width: 280px) {
      height: 180px;
      width: 180px;
    }

    @media (min-width: 360px) {
      margin-left: 0.5px;
    }

    @media (min-width: 375px) {
      margin-left: 15px;
    }

    @media (min-width: 400px) {
      margin-left: -10px;
      height: 195px;
      width: 195px;
    }

    @media (min-width: 768px) {
      height: 210px;
      width: 210px;
    }

    @include mediaQueries.break-point(desktop) {
      height: 255px;
      width: 255px;
    }

    @media (min-width: 1400px) {
      height: 280px;
      width: 280px;
    }

    @media (min-width: 2560px) {
      height: 300px;
      width: 300px;
    }

    &.active {
      left: 52%;
      transform: translateX(-18%);

      @media (min-width: 375px) {
        left: 50%;
      }

      @media (min-width: 425px) {
        left: 54%;
      }

      @include mediaQueries.break-point(desktop) {
        transform: translateX(-117%);
      }

      @include mediaQueries.break-point(desktop-large) {
        transform: translateX(-145%);
      }

      @media (min-width: 1366px) {
        left: 50%;
      }

      @include mediaQueries.break-point(desktop-xl) {
        transform: translateX(-143%);
      }

      @media (min-width: 1650px) {
        transform: translateX(-168%);
      }

      @media (min-width: 1650px) {
        transform: translateX(-175%);
      }

      @media (min-width: 1920px) {
        top: 0%;
        transform: translateX(-159%);
      }

      @media (min-width: 2200px) {
        transform: translateX(-185%);
        top: -10%;
      }

      @media (min-width: 2560px) {
        transform: translateX(-143%);
        left: 51%;
        top: 0%;
      }
    }
  }

  &Two {
    border: 10px solid colors.$color-pink-medium;
    top: 40%;
    left: 50%;
    transform: translateX(-100vw);
    transition: all 1s;

    @media (min-width: 280px) {
      height: 180px;
      width: 180px;
    }

    @media (min-width: 375px) {
      margin-left: 8px;
    }

    @media (min-width: 400px) {
      margin-top: 15px;
      margin-left: -7px;
      height: 195px;
      width: 195px;
    }

    @media (min-width: 768px) {
      height: 210px;
      width: 210px;
    }

    @include mediaQueries.break-point(desktop) {
      height: 255px;
      width: 255px;
      margin-left: 60px;
    }

    @media (min-width: 1400px) {
      height: 280px;
      width: 280px;
    }

    @media (min-width: 2560px) {
      height: 300px;
      width: 300px;
    }

    &.active {
      top: 11%;
      left: 48%;
      transform: translateX(-90%);

      @media (min-width: 375px) {
        left: 46%;
      }

      @media (min-width: 400px) {
        top: 13%;
        left: 50%;
      }

      @media (min-width: 414px) {
        left: 48%;
      }

      @include mediaQueries.break-point(desktop) {
        top: 25%;
        left: 43%;
        transform: translateX(-175%);
      }

      @include mediaQueries.break-point(desktop-large) {
        top: 23%;
        transform: translateX(-195%);
      }

      @media (min-width: 1366px) {
        left: 41%;
      }

      @include mediaQueries.break-point(desktop-xl) {
        transform: translateX(-209%);
        left: 43%;
      }

      @media (min-width: 1600px) {
        left: 38%;
        top: 6%;
      }

      @media (min-width: 1920px) {
        left: 42%;
        top: 8%;
      }

      @media (min-width: 2560px) {
        left: 47%;
        top: 10%;
      }
    }
  }

  &Three {
    background-image: colors.$color-gradient-three;
    border: 10px solid colors.$color-orange-light;
    top: 56%;
    left: 51%;
    transform: translateX(100vw);
    transition: all 1.25s;

    @media (min-width: 280px) {
      height: 180px;
      width: 180px;
      margin-top: -55px;
    }

    @media (min-width: 400px) {
      margin-top: 0;
      height: 195px;
      width: 195px;
    }
    @media (min-width: 768px) {
      height: 210px;
      width: 210px;
    }
    @media (min-width: 992px) {
      height: 255px;
      width: 255px;
    }
    @media (min-width: 1400px) {
      height: 280px;
      width: 280px;
    }
    @media (min-width: 2560px) {
      height: 300px;
      width: 300px;
    }

    &.active {
      top: 42%;
      transform: translateX(-19%);

      @media (min-width: 375px) {
        top: 44%;
      }

      @media (min-width: 414px) {
        top: 39%;
      }

      @media (min-width: 768px) {
        top: 43%;
      }

      @include mediaQueries.break-point(desktop) {
        top: 15%;
        left: 49%;
        transform: translateX(-30%);
      }

      @include mediaQueries.break-point(desktop-large) {
        transform: translateX(-50%);
      }

      @media (min-width: 1366px) {
        left: 44%;
      }

      @include mediaQueries.break-point(desktop-xl) {
        left: 50%;
        transform: translateX(-75%);
      }

      @media (min-width: 1600px) {
        left: 47%;
        top: 12%;
      }

      @media (min-width: 2560px) {
        left: 51%;
      }
    }
  }
}

.text {
  text-transform: uppercase;
  text-align: center;
  @include mixins.font(
    "Avenir" sans-serif,
    functions.rem(18),
    1.2,
    500,
    colors.$color-white
  );
  margin: 0 auto;
  padding: functions.em(5);

  &Strong {
    font-size: functions.rem(38);
    line-height: 1.1;
    display: block;
    font-family: "Atma", sans-serif;
    font-weight: 700;

    @media (min-width: 280px) {
      font-size: functions.rem(24);
    }

    @media (min-width: 360px) {
      font-size: functions.rem(28);
    }

    @media (min-width: 375px) {
      font-size: functions.rem(30);
    }

    @media (min-width: 400px) {
      font-size: functions.rem(32);
    }

    @media (min-width: 992px) {
      font-size: functions.rem(36);
    }

    @media (min-width: 1200px) {
      font-size: functions.rem(38);
    }

    @media (min-width: 1440px) {
      font-size: functions.rem(40);
    }
  }

  .superscript {
    position: relative;
    font-size: 0.6rem;
    top: 0.2rem;
    vertical-align: top;
    line-height: 0;
  }

  @media (min-width: 280px) {
    font-size: functions.rem(10);
  }

  @media (min-width: 360px) {
    font-size: functions.rem(12);
  }

  @media (min-width: 375px) {
    font-size: functions.rem(16);
    margin-top: -9px;
    // margin-left: 10px;
  }

  @media (min-width: 414px) {
    font-size: functions.rem(17);
    margin-top: -9px;
  }

  @media (min-width: 768px) {
    margin-top: -5px;
  }

  @media (min-width: 576px) {
    font-size: functions.rem(16);
  }
  @media (min-width: 760px) {
    font-size: functions.rem(20);
  }
  @media (min-width: 992px) {
    font-size: functions.rem(21);
  }
  @media (min-width: 1200px) {
    font-size: functions.rem(21);
  }
  @media (min-width: 1400px) {
    font-size: functions.rem(23);
  }
}

.lineWrap {
  display: block;
}

.lowercase {
  text-transform: lowercase;
}
