@import url("https://fonts.googleapis.com/css2?family=Atma:wght@400;500;600;700&display=swap");

/* @font-face {
  font-family: "Avenir";
  src: url('/path/to/font/filename.woff2') format('woff2'), 
       url('/path/to/font/filename.woff') format('woff');
} */

/* @font-face {
  font-family: "Atma";
  src: url('/path/to/font/filename.woff2') format('woff2'), 
       url('/path/to/font/filename.woff') format('woff');
} */

/* @font-face {
  font-family: "Gluten";
  src: url('/path/to/font/filename.woff2') format('woff2'), 
       url('/path/to/font/filename.woff') format('woff');
} */

/* Font Family variables */
$font-family-body: "Avenir", sans-serif;
$font-family-h1: "Atma", sans-serif;
$font-family-h2: "Atma", sans-serif;
$font-family-h3: "Avenir", sans-serif;
$font-family-h4: "Avenir", sans-serif;
$font-family-h5: "Avenir", sans-serif;
$font-family-h6: "Avenir", sans-serif;
$font-family-p: "Avenir", sans-serif;
$font-family-footnote: "Avenir", sans-serif;
$font-family-a: inherit;
$font-family-button: "Avenir", sans-serif;
$font-family-li: "Avenir", sans-serif;
