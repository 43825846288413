@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";
@use "tools/general.scss";
@use "tools/utilities.scss";

.toxicMeter {
	@extend .bg-image-baselines;
	width: 100%;
	max-width: 375px;
	height: 65px;
	padding: 0;
	margin: 0 auto;
	background-image: url("/media/bg-imgs/toxicity-meter.svg");
	background-size: cover;
	position: relative;
	isolation: isolate;
}

.meterNeedle {
	@extend .bg-image-baselines;
	width: 12px;
	height: 45px;
	padding: 0;
	margin: 0 auto;
	background-image: url("/media/bg-imgs/icon-meter-needle.svg");
	background-position: center center;
	background-size: cover;
	position: absolute;
	bottom: -18px;
	left: 4%;
	transform: translateX(-50%);
	opacity: 1;

	&.hidden {
		@extend .visually-hidden;
		opacity: 0;
	}
	&.level1 {
		@extend .transition-class;
		left: 25%;
	}
	&.level2 {
		@extend .transition-class;
		left: 60%;
	}
	&.level3 {
		@extend .transition-class;
		left: 95%;
	}
}

.transition-class {
	transition: all 0.3s ease-in-out 0.1s;
}

.toxicityResult {
	margin-top: functions.em(30);
	font-weight: bold;
	text-align: center;
}
