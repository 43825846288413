@use "tools/colors.scss";
@use "tools/fonts.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";

.footer {
  padding: functions.em(40) 0 functions.em(100);
  background-color: colors.$color-black;

  @include mediaQueries.break-point(desktop) {
    padding: functions.em(40) 0;
  }
}

.logo {
  width: 225px;
  height: auto;
  @include mixins.font(
    fonts.$font-family-body,
    functions.rem(16),
    functions.em(16),
    normal,
    colors.$color-white
  );

  @include mediaQueries.break-point(desktop) {
    width: 230px;
    margin-left: -25px;
  }
}

.text {
  margin-top: functions.em(13);
  font-size: functions.rem(16);
  color: colors.$color-white;

  @include mediaQueries.break-point(desktop) {
    margin-top: functions.em(10);
    font-size: functions.rem(18);
  }
}

.link {
  display: block;
  margin-top: functions.em(15);
  font-size: functions.rem(16);
  color: colors.$color-white;
  text-decoration: underline;

  @include mediaQueries.break-point(desktop) {
    display: inline-block;
    margin-right: functions.em(15);
    margin-top: functions.em(10);
    font-size: functions.rem(18);
  }
}
