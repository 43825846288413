@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";

.modal {
	display: none;
	width: calc(100% - 30px);
	max-width: 350px;
	margin: 0 auto;
	border-radius: 15px;
	border: none;
	isolation: isolate;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow-y: auto;
	background-color: colors.$color-white;

	@include mediaQueries.break-point(desktop) {
		width: 50%;
		right: 50%;
		transform: translate(-50%, -50%);
	}

	&.active {
		display: block;
		z-index: 2243005;
	}

	.header {
		width: fit-content;
		margin: functions.em(16) auto;
		padding: 0 0 functions.em(8);
		font-size: functions.rem(22);
		text-align: center;
		border-bottom: 1px solid grey;
	}

	.devList {
		.developer {
			display: block;
			margin: functions.em(8) auto 0;
			text-align: center;
		}
	}

	.buttonContainer {
		margin: functions.em(32) auto functions.em(16);
		display: flex;
		flex-direction: column;
		column-gap: 20px;

		button {
			width: 75%;
			margin: 0 auto;
			border-radius: 25px;
			background-color: colors.$color-white;
			border: 2px solid colors.$color-orange;

			&.continueBtn {
				background-color: colors.$color-orange-medium;
				color: colors.$color-white;
			}
		}
	}
}

.underlay {
	background: rgba(0, 0, 0, 0.6);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 2243004;
}
