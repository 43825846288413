/* General Colors */
$color-grey: #707070;
$color-black: #000000;
$color-white: #ffffff;
$color-box-shadow: rgba(0, 0, 0, 0.2);
$color-chatbot-box-shadow: rgba(0, 0, 0, 0.2);
$color-drop-shadow: rgba(8, 1, 1, 0.59);

/* Brand Colors */
$color-orange: #ff7d4a;
$color-orange-light: #f87828;
$color-orange-medium: #f04e0e;
$color-orange-dark: #d33c00;
$color-red-medium: #ff0000;
$color-red-dark: #c10000;
$color-yellow-bg: #f9fad8;
$color-yellow-medium: #ffe4af;
$color-pink-light: #ffe2e5;
$color-pink-medium: #ff6ca4;
$color-pink-dark: #cc2256;
$color-gradient-one: linear-gradient(
	to bottom,
	#fff3b2 0%,
	#fd528e 50%,
	#fd4488 60%,
	#ff3f6a 75%,
	#ff3b4d 100%
);
$color-gradient-two: linear-gradient(
	to bottom,
	#ff3b4d 0%,
	#ff3f6a 20%,
	#fd4488 30%,
	#fb49a0 40%,
	#fd528e 50%,
	#ff5e75 60%,
	#ff6c57 70%,
	#ff7b4c 80%,
	#ff8944 90%,
	#ff983a 100%
);
$color-gradient-three: linear-gradient(
	to top,
	#ff3b4d 0%,
	#ff3f6a 20%,
	#fd4488 30%,
	#fb49a0 40%,
	#fd528e 50%,
	#ff5e75 60%,
	#ff6c57 70%,
	#ff7b4c 80%,
	#ff8944 90%,
	#ff983a 100%
);
$color-gradient-four: linear-gradient(
	to bottom,
	#ff3b4d 0%,
	#ff3f6a 33%,
	#fd4488 66%,
	#fd528e 100%
);

/* Font Default Colors */
$color-font-body: $color-black;
$color-font-h1: $color-black;
$color-font-h2: $color-black;
$color-font-h3: $color-black;
$color-font-h4: $color-black;
$color-font-h5: $color-black;
$color-font-h6: $color-black;
$color-font-p: $color-black;
$color-font-footnote: $color-white;
$color-font-a: inherit;
$color-font-button: $color-orange-dark;
$color-font-li: $color-black;

/* Component Specific Colors */
/* Navigation */
$color-nav-button-bg-active: $color-orange-dark;
$color-nav-button-text-active: $color-black;
$color-nav-bg-default: $color-white;
$color-nav-text-default: $color-grey;

/* Animated Fact Circle */
$color-fact-border-MoreThan: $color-pink-dark;
$color-fact-border-EachYear: $color-pink-medium;
$color-fact-border-Every: $color-orange-light;
$color-fact-gradient-MoreThan: linear-gradient(
	30deg,
	#ff3b4d 0%,
	#ff3f6a 25%,
	#fd4488 35%,
	#fb49a0 45%,
	#fd528e 55%,
	#fc570a 100%
);
$color-fact-gradient-EachYear: linear-gradient(
	to bottom,
	#ff3b4d 0%,
	#ff3f6a 25%,
	#fd4488 35%,
	#fb49a0 45%,
	#fd528e 55%,
	#fc570a 100%
);
$color-fact-gradient-Every: linear-gradient(
	to bottom,
	#f5ae68 0%,
	#ff3b4d 15%,
	#ff3f6a 20%,
	#fd4488 30%,
	#fb49a0 40%,
	#fd528e 50%,
	#ff5e75 55%,
	#fc570a 70%,
	#ff7b4c 80%,
	#ff8944 90%,
	#ff7a00 100%
);

/* Modal */
$color-modal-sources: rgba(255, 0, 0, 0.3);
$color-modal-orange: $color-orange;

/* ADA Colors */
$focus_ring: blue;
