@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";
@use "ScannerModal.module.scss";
@use "tools/utilities.scss";

	.backButton {
		@extend .modal-nav-button;
		// color: colors.$color-orange-dark;
		color: colors.$color-white;

		@include mediaQueries.break-point(desktop) {
		}
	}

	.orange {
		color: colors.$color-orange-dark;
	}

	.hidden {
		@extend .hide-button;
	}
