@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";

.wrapper {
	width: 100%;
	max-width: 375px;
	margin: functions.em(6) auto functions.em(5);
	display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.partnerLogo {
  height: 70px;
	width: auto;
	margin: 10px auto;
	font-size: functions.rem(16);
	display: block;
  padding: 5px;
  padding-top: 0;

  @media (min-width: 375px) {
    height: 65px;
  }

  @media (min-width: 420px) {
    height: 70px;
  }
}
