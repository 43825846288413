@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mediaQueries.scss";
@use "tools/general.scss";

.sources {
  background-color: colors.$color-yellow-bg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: functions.em(40) 0;
  position: relative;

  &.active {
    padding-bottom: 0;
  }
}

.sources-button {
  background-color: unset;
  border: none;
  color: colors.$color-black;
  cursor: pointer;
  font-size: functions.rem(14);
  text-decoration: underline;

  &.active {
    opacity: 0;
    pointer-events: none;
  }

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(16);
  }
}

.container {
  background-color: colors.$color-grey;
  max-height: 0px;
  overflow: hidden;
  position: relative;
  transition: height 0.75s;
  // width: 100%;

  &.active {
    max-height: fit-content;
    padding: functions.em(20);
  }
}

.header {
  color: colors.$color-white;
  font-size: functions.rem(14);
  font-weight: 800;
  margin-top: 0;
  text-align: left;

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(16);
  }
}

.text {
  color: colors.$color-white;
  font-size: functions.rem(10);
  text-align: left !important;
  line-height: functions.em(24);
  margin-top: 0;
  font-weight: lighter;

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(14);
  }
}

.close-button {
  width: 20px;
  height: 20px;
  background-color: unset;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  margin-top: 0;

  &:after {
    @extend .bg-image-baselines;
    content: "";
    width: 20px;
    height: 20px;
    background-size: cover;
    background-image: url("/media/bg-imgs/icon-close.svg");
    position: absolute;
    top: 0;
    right: 0;
    font-weight: bold;
    filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(353deg) brightness(107%) contrast(100%);
  }
}