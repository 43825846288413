@use "tools/colors.scss";
@use "tools/fonts.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";
@use "tools/general.scss";
@use "tools/utilities.scss";

.scannerInput {
	position: absolute;
	top: -100%;
	z-index: -1;
}

.scannerButton {
	width: 100%;
	max-width: 340px;
	height: 50px;
	margin: functions.em(30) auto 0;
	@include mixins.font(
		fonts.$font-family-body,
		functions.rem(18),
		functions.em(16),
		bold,
		colors.$color-orange-dark
	);
	border: 3px solid colors.$color-orange-dark;
	border-radius: 50px;
	background-color: colors.$color-white;
	text-decoration: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 5;
	overflow: hidden;

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}

	@include mediaQueries.break-point(desktop) {
		margin-left: 0;
		max-width: 410px;
		padding: functions.em(25) 0;
	}

	i {
		width: 20px;
		height: 20px;
		margin-left: functions.em(10);
		position: relative;

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			font-weight: bold;
			@extend .bg-image-baselines;
		}

		&.hidden {
			// default style if no iconName is provided to the component
			@extend .visually-hidden;
		}

		&.camera {
			width: 30px;
			height: 25px;

			&:after {
				background-image: url("/media/bg-imgs/icon-camera.svg");
			}
		}
	}
}
