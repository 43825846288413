@use 'tools/colors.scss';
@use 'tools/typography.scss';
@use 'tools/functions.scss';
@use 'tools/mixins.scss';
@use 'tools/mediaQueries.scss';

.container {
  background-color: colors.$color-white;
  border: 2px solid colors.$color-red-dark;
  border-radius: 35px 0 0 35px;
  border-right: 0;
  box-shadow: -4px 4px 4px rgb(0 0 0 / 19%);
  right: 0;
  top: 0;
  margin-top: 10px;
  padding: 0 30px;
  width: fit-content;
  position: fixed;
  z-index: 19;


  @include mediaQueries.break-point(desktop) {
    border-radius: 35px;
    border: 3px solid colors.$color-red-dark;
    margin-top: 0;
    padding: 0 50px;
    right: 4%;
    top: 2px;
    z-index: 101;
  }
}

.content {
  padding: 5px 0;
  text-align: center;

  @include mediaQueries.break-point(desktop) {
    padding: 3px 0 0 0;
  }

  .text {
    @include mixins.font(false,
      functions.em(13),
      1,
      false,
      colors.$color-red-dark);
    margin-top: 2px;
    text-align: center;

    @include mediaQueries.break-point(desktop) {
      line-height: .75;
    }
  }

  .disclaimer {
    margin-top: 0;
    padding-bottom: 5px;
  }
}

.link {
  @include mixins.font(false,
    functions.em(13),
    1,
    bold,
    colors.$color-red-dark);
}