@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";
@use "ScannerModal.module.scss";

.closeButton {
	@extend .modal-nav-button;
	color: colors.$color-orange-dark;

	&.theme {
		color: colors.$color-white;
	}

	@include mediaQueries.break-point(desktop) {
	}
}
