@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mediaQueries.scss";

.navbar {
	width: 100%;
	padding: 0 0 15px;
	margin: 0 auto;
	background-color: colors.$color-white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 55px;
	position: fixed;
	bottom: -1px;
	isolation: isolate;
	z-index: 100;
	filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.478));

	@include mediaQueries.break-point(desktop) {
		justify-content: flex-start;
		bottom: unset;
		top: 0;
		height: 72px;
		//ADA - improves visibility of focus outline
		padding-top: 1px;
		// reset extra mobile nav padding
		padding-bottom: 0;
	}
}

.navList {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;

	@include mediaQueries.break-point(desktop) {
		max-width: 65%;
		margin-right: 0;
		margin-left: 3rem;
		justify-content: space-evenly;
	}
}

.navlink {
	max-width: 75px;
	margin-bottom: functions.em(7);
	padding: functions.em(5) 0 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	flex: 1;
	background-color: colors.$color-white;
	color: colors.$color-grey;
	font-family: Avenir, sans-serif;
	font-size: functions.rem(10);
	text-decoration: none;
	// hides the top of the Poison Signs nav icon => :nth-child(4) img
	overflow: hidden;

	@include mediaQueries.break-point(desktop) {
		width: 100%;
		max-width: unset;
		padding: 0;
		margin-top: unset;
		margin-bottom: 0;
		flex-direction: row;
		font-size: functions.rem(18);
		justify-content: center;
		//ADA - improves visibility of focus outline
		margin-right: 5px;
	}

	img {
		height: 30px;
		width: auto;
		margin-bottom: functions.em(5);
		opacity: 58%;

		@include mediaQueries.break-point(desktop) {
			margin-right: functions.em(10);
			height: 30px;
		}
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.scannerButton {
	align-items: flex-end;
	background: white url("/media/bg-imgs/nav-icon-scanner.svg") center no-repeat;
	background-position-y: 13px;
	border-radius: 50%;
	border: none;
	display: flex;
	filter: drop-shadow(0 0 0.35rem rgba(0, 0, 0, 0.421));
	justify-content: center;
	height: 70px;
	margin: 0;
	position: absolute;
	text-decoration: none !important;
	bottom: 5px;
	left: 3px;
	width: 70px;

	@include mediaQueries.break-point(desktop) {
		display: none;
		bottom: 0;
		left: 0;
	}

	span {
		color: #494949;
		font-size: 0.625em;
		pointer-events: none;
	}

	&.active {
		background-color: colors.$color-orange;

		&:after {
			width: 0;
		}

		span {
			color: colors.$color-black;
		}
	}
}

.active {
	color: colors.$color-nav-button-bg-active;
	font-weight: 700;

	&:after {
		content: "";
		width: 18%;
		position: absolute;
		bottom: 15px;
		height: 6px;
		background-color: colors.$color-nav-button-bg-active;

		@include mediaQueries.break-point(desktop) {
			height: 10px;
			bottom: 0;
		}
	}

	img {
		opacity: 100%;
		filter: invert(23%) sepia(93%) saturate(1734%) hue-rotate(6deg)
			brightness(108%) contrast(112%);
		-webkit-filter: invert(23%) sepia(93%) saturate(1734%) hue-rotate(6deg)
			brightness(108%) contrast(112%);
	}
}

.navPlaceholder {
	width: 65px;
}
