@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";

.wrapper {
	/* Class Styles */

	@include mediaQueries.break-point(desktop) {
	}

	h3 {
		margin-top: functions.em(20) !important;
	}
}

.photoFrame {
	width: 100%;
	max-width: 300px;
	height: 400px;
	background-color: grey;
	margin: functions.em(30) auto;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
    height: auto;
	}
}

.sources {
	padding: functions.em(15) functions.em(30);
	margin-top: functions.em(30);
	background-color: colors.$color-modal-sources;

	p {
		margin-top: functions.em(8) !important;
		@include mixins.font(false, functions.rem(12), false, normal, false);

		&:first-of-type {
			margin-top: 0 !important;
		}
	}
}
