// MIXIN FOR FONT STYLING
@mixin font($font_family: false, 
            $font_size: false, 
            $line_height: false, 
            $font_weight: false, 
            $color: false) {
  @if $font_family { font-family: $font_family; }
  @if $font_size { font-size: $font_size; }
  @if $line_height { line-height: $line_height; }
  @if $font_weight { font-weight: $font_weight; }
  @if $color { color: $color; }
}