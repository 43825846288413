@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mediaQueries.scss";

.offlineModal {
  display: none;
  width: auto;
  padding: 20px;
  margin: 15px;
  border-radius: 15px;
  border: none;
  isolation: isolate;
  position: fixed;
  top: 50%;
  transform: translate(0, -55%);
  overflow-y: auto;
  background-color: colors.$color-white;

  @include mediaQueries.break-point(desktop) {
    width: 50%;
    right: 50%;
    transform: translate(50%, -55%);
  }

  &.active {
    display: block;
    z-index: 2243002;
  }

  .text {
    text-align: center;
    font-weight: bold;
    padding: 0 20px;
    margin: 10px 0 0;
  }

  .header {
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }

  .buttonClose {
    width: 20px;
    height: 20px;
    background-color: unset;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    margin-top: 0;

    &:after {
      @extend .bg-image-baselines !optional;
      content: "";
      width: 20px;
      height: 20px;
      background-size: cover;
      background-image: url("/media/bg-imgs/icon-close.svg");
      position: absolute;
      top: 0;
      right: 0;
      font-weight: bold;
      filter: invert(27%) sepia(52%) saturate(7498%) hue-rotate(21deg) brightness(99%) contrast(102%);
    }
  }
}

.underlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2243001;
}