@use "tools/colors.scss";
@use "tools/fonts.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";

.wrapper {
	// must be relative so that floating poison icons share the same anchoring element
	position: relative;
	z-index: 1;
}

.bgWrapper {
	width: 100%;
	background: rgba(255, 255, 255, 0.7);
	border-radius: 25px;
}

.skullContainer {
	max-width: 340px;
	min-height: 160px;
	padding: 20px;
	margin: 20px auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;

	@include mediaQueries.break-point(desktop) {
		width: unset;
		max-width: unset;
		justify-content: center;
	}

	h3 {
		margin-top: 0;
		@include mixins.font(
			fonts.$font-family-body,
			functions.rem(18),
			functions.em(22),
			bold,
			colors.$color-black
		);

		@include mediaQueries.break-point(desktop) {
			font-size: functions.rem(22);
			text-align: center;
			max-width: 320px;
		}

		&.activeHeader {
			@include mediaQueries.break-point(desktop) {
				font-size: functions.rem(32);
			}
		}
	}

	p {
		margin-top: functions.em(8);
		@include mixins.font(
			fonts.$font-family-body,
			functions.rem(14),
			functions.em(20),
			normal,
			colors.$color-black
		);

		@include mediaQueries.break-point(desktop) {
			font-size: functions.rem(22);
			text-align: center;
		}
	}
}

.skullImg {
	width: 106px;
	height: 85px;
	margin: 35px 10px 35px 0;
	margin: 0 20px 0 0;
	position: relative;

	@include mediaQueries.break-point(desktop) {
		width: 168px;
		height: 134px;
		margin: 0 100px 0 0;
	}
}

.textBox {
	// height specified to prevent jumping when icon is clicked
	height: 145px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	@media (min-width: functions.em(375)) {
		// height specified to prevent jumping when icon is clicked
		height: 110px;
	}

	@media (min-width: functions.em(400)) {
		// height no longer needed and reset to default
		height: unset;
	}

	@include mediaQueries.break-point(desktop) {
		width: 100%;
		max-width: 450px;
		align-items: center;
	}

	.hide {
		display: none;
	}
}

.poisonListContainer {
	font-size: 12px;
	font-weight: bold;
	display: grid;
	justify-items: center;
	grid-template-rows: auto;
	grid-template-columns: repeat(6, 1fr);
	gap: 8px;

	@include mediaQueries.break-point(desktop) {
		grid-template-columns: repeat(11, 1fr);
	}
}

.iconTarget {
	// used to guide the flying icons into the dog's mouth
	width: 40px;
  height: 40px;
	position: absolute;
	top: 100px;
	left: 52px;
	// background gradient used to paint the target area
	// background: radial-gradient(
	// 	circle,
	// 	red 0%,
	// 	red 25%,
	// 	white 25%,
	// 	white 50%,
	// 	red 50%,
	// 	red 75%,
	// 	white 75%,
	// 	white 100%
	// );

	@include mediaQueries.break-point(desktop) {
		width: 65px;
		left: 171px;
	}

	@include mediaQueries.break-point(desktop-large) {
		left: 261px;
	}

	@include mediaQueries.break-point(desktop-xl) {
		left: 351px;
	}
}
