@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mediaQueries.scss";

.wrapper {
	min-height: fit-content;
	margin: 0 auto;
	padding: functions.em(50) 0 functions.em(70);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	@include mediaQueries.break-point(desktop) {
		flex-direction: row;
		justify-content: space-between;
		padding-top: functions.em(20);
		padding-bottom: functions.em(160);
	}
}

.list {
	width: 100%;

	@include mediaQueries.break-point(desktop) {
		width: 49%;
    margin-top: 10%;
	}

	&:last-of-type {
		margin-top: functions.em(20);

		@include mediaQueries.break-point(desktop) {
			margin-top: 10%;
		}
	}
}
