@use 'tools/colors.scss';
@use 'tools/typography.scss';
@use 'tools/functions.scss';
@use 'tools/mediaQueries.scss';

.wrapper {

  @include mediaQueries.break-point(desktop) {
    display: flex;
    flex: 1;
    column-gap: 5rem;
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 21rem;
  min-height: fit-content;
  padding: functions.em(20) 20px functions.em(40) 20px;

  @include mediaQueries.break-point(desktop) {
    column-gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 120rem;
    max-width: 100%;
  }
}