@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mediaQueries.scss";
@use "tools/general.scss";

.toggle {
	width: 100%;
	min-height: 80px;
	margin: functions.em(20) auto 0;
	border-radius: 55px;
	box-shadow: 3px 3px 10px colors.$color-box-shadow;
	background-color: colors.$color-red-dark;
	border: 0.25px solid transparent;
	/* Hide content overflow */
	overflow: hidden;
	/* New z-index context for each toggle */
	isolation: isolate;
	z-index: 1;
	/* Set positions of all children relative to the parent */
	position: relative;
	//helps with background color bleeding
	-moz-background-clip: padding-box;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;

	&:first-of-type {
		margin-top: 0;

		@include mediaQueries.break-point(desktop) {
			margin: 1.25em auto 0;
		}
	}

	@include mediaQueries.break-point(desktop) {
		max-width: 500px;
	}

	p {
		padding: 0;
		margin: 0;
		font-size: functions.rem(16);
		line-height: functions.em(21);

		@include mediaQueries.break-point(desktop) {
		}
	}
}

.symptoms {
	/* Fill the full parent container */
	width: 100%;
	height: 100%;
	/* Font styles */
	color: colors.$color-white;
	/* Background styles */
	/* Text positioning */
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	/* Element Position and Stacking */
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	@include mediaQueries.break-point(desktop) {
	}

	p {
		/* position text so that it's not hidden behind the button */
		margin-left: 30px;
		margin-right: 90px;
		font-size: functions.rem(15);
		color: colors.$color-white;

		@include mediaQueries.break-point(desktop) {
		}
	}
}

.foods {
	/* Fill the full parent container */
	width: 100%;
	height: 100%;
	/* position left egde of div behind button */
	margin-left: 45px;
	/* Font styles */
	color: colors.$color-black;
	/* Background styles */
	background-color: colors.$color-yellow-bg;
	/* Text positioning */
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	/* Element Position and Stacking */
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;

	@include mediaQueries.break-point(desktop) {
	}

	p {
		/* position text so that it's not hidden behind the button */
		margin-left: 50px;
		margin-right: 100px;

		@include mediaQueries.break-point(desktop) {
		}
	}
}

.btn {
	/* Size the toggle button */
	width: 83px;
	height: 83px;
	margin: 0;
	/* Border, background and shadow styles */
	background-color: colors.$color-white;
	border: none;
	border-radius: 50%;
	box-shadow: 3px 3px 10px colors.$color-box-shadow;
	/* Position the toggle button */
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;

	@include mediaQueries.break-point(desktop) {
	}

	&.keyboard {
		&:focus {
			outline: 0;

			&::before {
				content: "";
				width: 92%;
				height: 93%;
				border-radius: 50%;
				position: absolute;
				top: 2px;
				left: 3px;
				box-shadow: 0 0 0 0.2rem rgb(0, 123, 255);
				z-index: 10;
			}
		}
	}

	img {
		width: 60%;
		height: auto;
		display: block;
		margin: 0 auto;
		/* Make the image non-clickable */
		pointer-events: none;

		&.icon-11 {
			width: 50%;
		}

		@include mediaQueries.break-point(desktop) {
		}
	}

	&.active {
		&::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0px;
			left: -2px;
			background-image: url("/media/bg-imgs/icon-cross-through.svg");
			background-repeat: no-repeat;
			background-size: cover;
		}

		img {
			&.icon-1,
			&.icon-3,
			&.icon-4,
			&.icon-6 {
				padding-right: 6px;
			}
		}
	}
}

.foods {
	/* css psuedo animation for button clicks */
	left: 0;
	transition: left 0.2s linear 0.15s;

	@include mediaQueries.break-point(desktop) {
	}

	&.active {
		/* css psuedo animation for button clicks */
		left: calc(100% - 80px);
		transition: left 0.2s linear 0.15s;

		@include mediaQueries.break-point(desktop) {
		}
	}
}

.btn {
	/* css psuedo animation for button clicks */
	left: -1px;
	top: -2px;
	transition: left 0.2s linear 0.15s;

	@include mediaQueries.break-point(desktop) {
	}

	&.active {
		/* css psuedo animation for button clicks */
		left: calc(100% - 80px);
		transition: left 0.2s linear 0.15s;

		@include mediaQueries.break-point(desktop) {
		}
	}
}
