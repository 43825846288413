@use 'tools/colors.scss';
@use 'tools/typography.scss';
@use 'tools/functions.scss';
@use 'tools/mediaQueries.scss';

  .section {
    min-height: fit-content;
    margin: 0 auto;
    padding: functions.em(50) 50px;
    border-radius: 0% 0% 47% 55% / 0% 0% 9% 20%;
    // background-image: colors.$color-gradient-two;

    @include mediaQueries.break-point(desktop) {

    }
  }

  .text {
    color: colors.$color-white;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
  }

  .wrapper {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    max-width: 375px;

    @include mediaQueries.break-point(desktop) {

    }
  }
