@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";

.wrapper {
  width: 100%;
  padding: functions.em(160) 0 functions.em(30);
  position: absolute;
  top: 0;
  left: 0;
  transition: left 0.2s linear 0.1s;

  &.collapse {
    padding: 0;
    left: -100%;
    transition: left 0.2s linear 0.1s;
  }
}

hr {
  margin: functions.em(35) 0 0;
  border: 2px dashed colors.$color-orange-dark;
}

.modalText {
  text-align: center;
}