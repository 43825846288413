@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";

.modal {
  width: calc(100% - 30px);
  height: 86%;
  padding: 0;
  margin: 15px;
  border-radius: 15px;
  border: none;
  background-color: #fff;
  isolation: isolate;
  position: fixed;
  top: 0;
  overflow-y: auto;
  display: none;

  // @include mediaQueries.break-point(desktop) {
  // 	display: none !important;
  // }

  &.active {
    display: block;
    z-index: 20;
  }

  h3 {
    margin-top: functions.em(15);
    text-align: center;
  }

  p {
    margin-top: functions.em(20);
    line-height: functions.em(22);
  }
}

.backdrop {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 19;

  @include mediaQueries.break-point(desktop) {
    display: none !important;
  }
}

.modalNav {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

// default styles for bg images on modal buttons
.modal-nav-button {
  width: fit-content;
  height: 25px;
  margin: 0;
  border: none;
  background-color: transparent;
}

.posionModalCurve {
  position: relative;
  height: 110px;
  width: 100%;
  background-image: colors.$color-gradient-three;
  z-index: 1;
}

.dogAnimation {
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 2;
  display: none;

  @media (min-width: 375px) {
    top: -5%;
  }

  @media (min-width: 500px) {
    width: 75%;
    left: 70px;
  }

  @include mediaQueries.break-point(landscape-phone) {
    top: -10%;
    left: 90px;
  }

  @media (min-width: 700px) {
    width: 65%;
    left: 130px;
  }

  @include mediaQueries.break-point(tablet) {
    width: 60%;
    top: -13%;
    left: 150px;
  }

  @media (min-width: 900px) {
    top: -16%;
    left: 200px;
  }

  @media (min-height: 1000px) {
    top: -8%;
  }

  &.active {
    display: block;
  }

  video,
  img {
    max-width: 100%;
  }
}