@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/mixins.scss";
@use "tools/functions.scss";
@use "tools/mediaQueries.scss";
@use "tools/general.scss";

.poisonControlClip {
  position: absolute;
  height: 101%;
  width: 100%;
  background-color: colors.$color-yellow-bg;
}

.introBottomCurve {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 10vw;
  width: 100%;
  transform: translateY(98%);
  z-index: 1;
  background-color: colors.$color-yellow-bg;
}

.topPoisonsTopCurve {
  position: absolute;
  top: 0;
  height: 10vw;
  transform: translateY(-1%);
  width: 100%;
  z-index: 1;
  background-color: #fd528e;
}

.humanFoodTopCurve {
  position: absolute;
  top: 0;
  height: 15vw;
  width: 100%;
  transform: translateY(-98%);
  background-color: colors.$color-pink-light;

  @include mediaQueries.break-point(desktop) {
    height: 13vw;
  }
}

.humanFoodBottomCurve {
  position: absolute;
  bottom: 0;
  height: 19vw;
  max-height: 60px;
  width: 100%;
  transform: translateY(98%);
  background-color: colors.$color-pink-light;
  z-index: 1;

  @include mediaQueries.break-point(desktop) {
    max-height: initial;
    height: 14vw;
  }
}

.poisonSignTopCurve {
  position: absolute;
  top: 0;
  height: 10vw;
  width: 100%;
  transform: translateY(-98%);
  background-color: colors.$color-pink-light;
  z-index: 1;

  @include mediaQueries.break-point(desktop) {
    height: 15vw;
    transform: translateY(-70%);
  }
}

.poisonSignBottomCurve {
  position: absolute;
  bottom: 0;
  height: 10vw;
  width: 100%;
  transform: translateY(98%);
  z-index: 1;

  @include mediaQueries.break-point(desktop) {
    background-color: colors.$color-pink-light;
    height: 8vw;
  }
}

.dogSafetyCardTopCurve {
  position: absolute;
  top: 0;
  left: 0;
  height: 4vw;
  width: 100%;
  transform: translateY(-98%);
  background-color: colors.$color-yellow-bg;

  @include mediaQueries.break-point(desktop) {
    height: 8vw;
    background-color: colors.$color-pink-light;
  }
}

.dogSafetyCardBottomCurve {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 12vw;
  width: 100%;
  transform: translateY(98%);
  z-index: 2;
  background-color: colors.$color-yellow-bg;

  @include mediaQueries.break-point(desktop) {
    height: 6vw;
  }
}

.partnershipTopCurve {
  position: absolute;
  top: 0;
  height: 12vw;
  width: 100%;
  transform: translateY(-98%);
  z-index: 2;
  background-color: colors.$color-yellow-bg;

  @include mediaQueries.break-point(desktop) {
    height: 6vw;
  }
}