@use "tools/colors.scss";
@use "tools/fonts.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";
@use "tools/general.scss";

.launchButton {
	width: 90px;
	height: 90px;
	margin-top: 0.5em;
	text-align: center;
	border-radius: 50%;
	border: none;
	background-color: colors.$color-white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// must be relative poision for :before and :after
	position: relative;
	grid-column: span 2;
	box-shadow: 0px 0px 15px colors.$color-box-shadow;
	isolation: isolate;

	@include mediaQueries.break-point(desktop) {
		width: 135px;
		height: 135px;
	}

	&:nth-child(4),
	&:nth-child(9) {
		grid-column: 2/4;

		@include mediaQueries.break-point(desktop) {
			grid-column: span 2;
		}
	}

	&:nth-child(5),
	&:nth-child(10) {
		grid-column: 4/6;

		@include mediaQueries.break-point(desktop) {
			grid-column: span 2;
		}
	}

	&:nth-child(6),
	&:nth-child(7),
	&:nth-child(8),
	&:nth-child(9),
	&:nth-child(10) {
		// @include mediaQueries.break-point(tablet) {
		// 	transform: translateX(67px);
		// }
		@include mediaQueries.break-point(desktop) {
			transform: translateX(90px);
		}

		@include mediaQueries.break-point(desktop-large) {
			transform: translateX(105px);
		}

		@include mediaQueries.break-point(desktop-xl) {
			transform: translateX(120px);
		}
	}

	&.active {
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-image: url("/media/bg-imgs/icon-cross-through.svg");
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	&:hover,
	&:focus {
		text-decoration: none;
		cursor: pointer;
	}
}

.iconWrapper {
	width: 40px;
	height: 40px;
	margin-top: functions.em(-4);
	// display: block;
	position: relative;

	@include mediaQueries.break-point(desktop) {
		width: 65px;
		height: 65px;
	}
}

button {
	text-decoration: none !important;
}

.icon {
	@extend .bg-image-baselines;
	background-position: center;
	width: 40px;
	height: 40px;
	position: absolute;
	top: 0;
	left: 0;
	transition: all 1s;
	z-index: 2;

	@include mediaQueries.break-point(desktop) {
		width: 65px;
		height: 65px;
	}
}

.text {
	padding: 0 10px;
	margin: functions.em(8) auto 0;
	@include mixins.font(
		fonts.$font-family-body,
		functions.rem(10),
		functions.em(16),
		bold,
		colors.$color-black
	);
	text-align: center;
	max-width: 150px;
	pointer-events: none;

	@include mediaQueries.break-point(desktop) {
		padding: 0 15px;
		font-size: functions.rem(14);
	}
}
