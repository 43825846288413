@use 'tools/colors.scss';
@use 'tools/typography.scss';
@use 'tools/functions.scss';
@use 'tools/mediaQueries.scss';

.wrapper {
  align-items: center;
  background-color: colors.$color-white;
  border-radius: 55px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: functions.em(20);
  padding-left: 20px;
  min-height: 60px;
  max-height: 90px;

  &:nth-child(8) {
    margin-bottom: 0;
  }

  @include mediaQueries.break-point(desktop) {
    min-height: 90px;
    min-width: 280px;
  }

  @include mediaQueries.break-point(desktop-xl) {
    min-height: 90px;
    min-width: 360px;
  }
}

.text {
  color: colors.$color-red-dark;
  padding-left: 15px;
  margin-top: 0px !important;
}

.image {
  max-width: 3.2em;
  padding: functions.em(10) 0;
  width: 49px;
  height: 49px;

  @include mediaQueries.break-point(desktop) {
    max-width: 4em;
    padding: functions.em(10) 0;
    width: unset;
    height: unset;
  }
}