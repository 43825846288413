@use "colors.scss";
@use "fonts.scss";
@use "functions.scss";
@use "mixins.scss";
@use "mediaQueries.scss";

body {
  padding: 0;
  margin: 0;
  @include mixins.font(fonts.$font-family-body,
    functions.rem(16),
    1.2,
    normal,
    colors.$color-font-body);
  letter-spacing: 0px;

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(19);
    line-height: functions.em(24);
  }
}

h1,
.h1 {
  padding: 0;
  margin: 0;
  @include mixins.font(fonts.$font-family-h1,
    functions.rem(32),
    functions.em(32),
    bold,
    colors.$color-font-h1);

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(48);
    // line-height: 1.7;
  }
}

h2,
.h2 {
  padding: 0;
  margin: 0;
  @include mixins.font(fonts.$font-family-h2,
    functions.rem(32),
    functions.em(20),
    600,
    colors.$color-font-h2);

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(44);
    // line-height: 52px;
  }
}

h3,
.h3 {
  padding: 0;
  margin: functions.em(20) 0 0;
  @include mixins.font(fonts.$font-family-h3,
    functions.rem(32),
    functions.em(20),
    normal,
    colors.$color-font-h3);

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(36);
    // line-height: 24px;
  }
}

h4,
.h4 {
  padding: 0;
  margin: functions.em(16) 0 0;
  @include mixins.font(fonts.$font-family-h4,
    functions.rem(24),
    functions.em(38),
    bold,
    colors.$color-font-h4);

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(32);
    // line-height: 27px;
  }
}

h5,
.h5 {
  padding: 0;
  margin: functions.em(16) 0 0;
  @include mixins.font(fonts.$font-family-h5,
    functions.rem(20),
    functions.em(38),
    bold,
    colors.$color-font-h5);

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(28);
    // line-height: 27px;
  }
}

h6,
.h6 {
  padding: 0;
  margin: functions.em(16) 0 0;
  @include mixins.font(fonts.$font-family-h6,
    functions.rem(20),
    functions.em(38),
    bold,
    colors.$color-font-h6);

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(28);
    // line-height: 27px;
  }
}

p,
.p {
  padding: 0;
  margin: functions.em(30) 0 0;
  @include mixins.font(fonts.$font-family-p,
    functions.rem(19),
    functions.em(26),
    500,
    colors.$color-font-p);
  text-align: left;
  font-weight: bold;

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(22);
    line-height: functions.rem(30);
  }
}

a,
button {
  text-decoration: underline;

  @include mediaQueries.break-point(dekstop) {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

a {
  padding: 0;
  margin: 0;
  @include mixins.font(fonts.$font-family-a,
    inherit,
    inherit,
    inherit,
    colors.$color-font-a);
}

button {
  padding: 0;
  margin: functions.em(24) 0 0;
  @include mixins.font(fonts.$font-family-button,
    functions.rem(18),
    functions.em(28),
    bold,
    colors.$color-font-button);

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(22);
  }
}

ul,
ol {
  padding: 0;
  margin: functions.em(16) 0 0;

  @include mediaQueries.break-point(desktop) {
    margin: functions.em(20) 0 0;
  }

  li {
    margin: functions.em(8) 0 0 8px;
    @include mixins.font(fonts.$font-family-li,
      functions.rem(18),
      1.2,
      normal,
      colors.$color-font-li);
    list-style-type: disc;

    @include mediaQueries.break-point(desktop) {
      margin: functions.em(12) 0 0 12px;
      font-size: functions.rem(24);
      // line-height: 27px;
    }

    &::marker {
      color: currentColor;
    }
  }
}

sup {
  font-size: 0.55em;
  position: relative;
  top: -0.65em;
  left: 0.1em;
}

.footnote {
  margin: functions.em(24) 5px 0;
  @include mixins.font(fonts.$font-family-footnote,
    functions.rem(10),
    functions.em(15),
    normal,
    colors.$color-font-footnote);
  text-align: left;

  @include mediaQueries.break-point(desktop) {
    font-size: functions.rem(18);
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.hang-footnote {
  margin-left: -7px;
}

.bold {
  font-weight: bold;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

.italic {
  font-style: italic;
}