@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";

.disclaimerModal {
  display: none;
  width: auto;
  padding: 20px;
  margin: 15px;
  border-radius: 15px;
  border: none;
  isolation: isolate;
  position: fixed;
  top: 50%;
  transform: translate(0, -55%);
  overflow-y: auto;
  background-color: colors.$color-white;

  @include mediaQueries.break-point(desktop) {
    width: 50%;
    right: 50%;
    transform: translate(50%, -55%);
  }

  &.active {
    display: block;
    z-index: 2243002;
  }

  .disclaimerText {
    text-align: center;
    font-weight: bold;
    padding: 0 20px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    column-gap: 20px;

    button {
      width: 75%;
      margin: 10px auto;
      border-radius: 25px;
      background-color: colors.$color-white;
      border: 2px solid colors.$color-orange;

      &.continueBtn {
        background-color: colors.$color-orange-medium;
        color: colors.$color-white;
        margin-top: 20px;
      }
    }
  }
}

.underlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2243001;
}