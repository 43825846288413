@use "colors.scss";
@use "typography.scss";
@use "functions.scss";
@use "mixins.scss";
@use "mediaQueries.scss";

/* Utility styling */

.container {
	// width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@include mediaQueries.break-point(landscape-phone) {
		max-width: 540px;
	}

	@include mediaQueries.break-point(tablet) {
		max-width: 720px;
	}

	@include mediaQueries.break-point(desktop) {
		max-width: 960px;
	}

	@include mediaQueries.break-point(desktop-large) {
		max-width: 1140px;
	}

	@include mediaQueries.break-point(desktop-xl) {
		max-width: 1320px;
	}
}

.container-fluid {
	width: 100%;
}

// margin utilities
.mt-0 {margin-top: 0;}
.mt-1 {margin-top: 0.5em;}
.mt-2 {margin-top: 1em;}
.mt-3 {margin-top: 1.5em;}
.mt-4 {margin-top: 3em;}
.mt-5 {margin-top: 5em;}

.mb-0 {margin-bottom: 0;}
.mb-1 {margin-bottom: 0.5em;}
.mb-2 {margin-bottom: 1em;}
.mb-3 {margin-bottom: 1.5em;}
.mb-4 {margin-bottom: 3em;}
.mb-5 {margin-bottom: 5em;}

.mr-0 {margin-right: 0;}
.mr-1 {margin-right: 0.5em;}
.mr-2 {margin-right: 1em;}
.mr-3 {margin-right: 1.5em;}
.mr-4 {margin-right: 3em;}
.mr-5 {margin-right: 5em;}

.ml-0 {margin-left: 0;}
.ml-1 {margin-left: 0.5em;}
.ml-2 {margin-left: 1em;}
.ml-3 {margin-left: 1.5em;}
.ml-4 {margin-left: 3em;}
.ml-5 {margin-left: 5em;}

// padding utilities
.pt-0 {padding-top: 0;}
.pt-1 {padding-top: 0.5em;}
.pt-2 {padding-top: 1em;}
.pt-3 {padding-top: 1.5em;}
.pt-4 {padding-top: 3em;}
.pt-5 {padding-top: 5em;}

.pb-0 {padding-bottom: 0;}
.pb-1 {padding-bottom: 0.5em;}
.pb-2 {padding-bottom: 1em;}
.pb-3 {padding-bottom: 1.5em;}
.pb-4 {padding-bottom: 3em;}
.pb-5 {padding-bottom: 5em;}

.pr-0 {padding-right: 0;}
.pr-1 {padding-right: 0.5em;}
.pr-2 {padding-right: 1em;}
.pr-3 {padding-right: 1.5em;}
.pr-4 {padding-right: 3em;}
.pr-5 {padding-right: 5em;}

.pl-0 {padding-left: 0;}
.pl-1 {padding-left: 0.5em;}
.pl-2 {padding-left: 1em;}
.pl-3 {padding-left: 1.5em;}
.pl-4 {padding-left: 3em;}
.pl-5 {padding-left: 5em;}

.dropShadow {
	filter: drop-shadow(0px 3px 6px colors.$color-drop-shadow);
}

.visually-hidden {
	width: 1px;
	height: 1px;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	overflow: hidden;
	white-space: nowrap;
	position: absolute;
  visibility: hidden;
}

.hide-button {
	pointer-events: none;
	visibility: hidden;
}

.sup-left {
	left: -2px;
}

.adjust-right-sm {
	margin-right: 4px;
}
