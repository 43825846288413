@use "colors.scss";
@use "typography.scss";
@use "functions.scss";
@use "mixins.scss";
@use "mediaQueries.scss";

body .kommunicate-custom-iframe {
	width: 84px;
	height: 84px;
	bottom: -3px;
	right: -4px;

	@include mediaQueries.break-point(desktop) {
		width: 99px;
		height: 99px;
		bottom: 0;
		right: 0;
	}

	&.km-iframe-dimension-no-popup {
		width: 390px;
		height: 600px;
	}

	&.km-iframe-closed {
		width: 85px !important;
		@include mediaQueries.break-point(desktop) {
			width: 100px !important;
		}
	}
}
