@use 'tools/colors.scss';
@use 'tools/typography.scss';
@use 'tools/functions.scss';
@use 'tools/mediaQueries.scss';

/* Template styles */
.container {
  display: flex;
  margin: 0 auto;
  padding: functions.em(20);
  align-items: center;

  &-warning {
    @include mediaQueries.break-point(desktop) {
      align-items: flex-start;
    }

    img {
      @include mediaQueries.break-point(desktop) {
        margin-top: 10px;
      }
    }
  }
}

.text {
  margin: 0;
  font-weight: bold;

  &-warning {
    @extend .text;
    color: colors.$color-red-dark;
    margin-left: 20px;

    @include mediaQueries.break-point(desktop) {
      width: 255px;
      ;
    }
  }

  &-social {
    @extend .text;
    color: colors.$color-white;
    margin-left: 10px;
  }
}

.href {

  &-warning {
    @extend .href;
    text-decoration: none;
  }
}

.img {

  &-social {
    width: 36px;
    height: 36px;
  }

  &-warning {
    width: 71px;
    height: 65px;
  }
}

/* End */