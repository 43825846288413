// ==========================================================================
// ADA Compliance Helpers
// ==========================================================================

@use "./colors.scss";

/*
  FOCUS RING INDICATION TOGGLING
*/
// Deactivate focus ring by default
a,
button,
video,
input {
	text-decoration: none;
	&:focus,
	&:hover {
		text-decoration: none;
		outline: none;
	}
}

// Activate focus ring for users navigating via keyboard
.keyboard-user {
	a,
	button,
	video,
	input,
	label {
		&:focus,
		&:focus-within {
			text-decoration: none;
			outline: 4px solid colors.$focus_ring;
		}
		&.nav-link,
		&.navbar-brand,
		&.footer-link {
			&:focus {
				outline: 4px solid colors.$focus_ring;
			}
		}
	}
}
