@use "tools/colors.scss";
@use "tools/typography.scss";
@use "tools/functions.scss";
@use "tools/mixins.scss";
@use "tools/mediaQueries.scss";
@use "tools/utilities.scss";

.poisonControlCTA {
	display: block;
	margin-top: functions.em(20);
	padding: functions.em(20);
	color: colors.$color-white;
	text-align: center;
	border: none;
	border-radius: 50px;
	background-color: colors.$color-red-dark;
}

.hidden {
	@extend .visually-hidden;
}
