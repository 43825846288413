html {
	scroll-behavior: smooth;
}

.bg-image-baselines {
	background: 0px 0px / contain no-repeat content-box;
}

.red-slash-baselines {
	content: "";
	width: 95%;
	height: 10px;
	background-color: #ff6060;
	position: absolute;
	top: 45%;
	left: 3px;
	transform: rotate(45deg);
}

.red-circle-baselines {
	content: "";
	width: 78%;
	height: 78%;
	border: 9px solid #ff6060;
	border-radius: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
